export const remisions = (data) => {
    let products = "";
    data.products.forEach(product => {
        products += `<tr><td></td><td>${product.amount}</td><td>KG</td><td><span>${product.sku}<span><p>${product.name}<p></td></tr>`;
    });


    let html = `<!DOCTYPE html>
    <html lang="es">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Frutamía</title>
    
        <style>
            .header{
                display: flex;
                margin-bottom:1.5rem;
            }
            .left{
                width:40%;
            }
            .right{
                width:60%;
                position: relative;
            }
            .left img{
                width:100%;
            }
            p,h2,h3{
                margin:0;
            }
    
            h2.store{
                position:absolute;
                bottom:0;
                right:0;
            }
    
            table{
                width:100%;
            }
            table th:nth-child(1){
                width:10%;
            }
            table th:nth-child(2){
                width:20%;
            }
            table th:nth-child(3){
                width:20%;
            }
            table th:nth-child(4){
                width:50%;
            }
            table th{
                background-color:#000;
                color:#fff;
                text-align:left;
            }
            table td span{
                font-size:8pt;
            }
        </style>
    </head>
    <body>
        <div class="header">
            <div class="left">
                <img src="https://frutamia.com.mx/assets/img/logo.png" />
                <div class="client-info">
                    <h3>Tiendas Chedraui S.A. de C.V.</h3>
                    <p>RFC: TCH850701RM1</p>
                    <p>AV. CONSTITUYENTES, 1150, LOMAS ALTAS, 11950, DELEGACIÓN HIDALGO, CIUDAD DE MÉXICO, MÉXICO</p>
                </div>
            </div>
            <div class="right">
                <h2>Frutamía S.A. de C.V.</h2>
                <p>RFC: FRU170125IPA</p>
                <P>DIRECCIÓN: NAVE "E" BODEGA 40, CENTRAL DE ABASTOS, 72019, PUEBLA, PUEBLA, MÉXICO</P>
                <P>TELÉFONO: (222)1 22 86 12</P>
                <h2 class="store">${data.storeText}</h2>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th style="background-color:#000;"></th>
                    <th>CANTIDAD</th>
                    <th>UNIDAD</th>
                    <th>DESCRIPCIÓN</th>
                </tr>
            </thead>
            <tbody>
                ${products}
            </tbody>
        </table>
    </body>
    </html>`;

    return html;
}