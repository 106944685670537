import "../../assets/css/tabsCard.css";
import { useEffect, useState } from "react";

export const TabsCard = ({children}) => {
    const [ active, setActive ] = useState(0);
    const [ tabs, setTabs ] = useState([]);
    const [ sections, setSections ] = useState([]);

    useEffect(() => {
        const sectionsAux = [];
        const tabsAux = [];

        if(children.props){
            tabsAux.push(<TabButton key={children.props.title} index={0} active={active} activeChanger={setActive}>{children.props.title}</TabButton>);
            sectionsAux.push(<TabItem key={children.props.title} index={0} active={active}>{children.props.children}</TabItem>);
        }else{
            
            children.forEach((item, itemIndex) => {
                sectionsAux.push(<TabItem key={item.props.title} index={itemIndex} active={active}>{item.props.children}</TabItem>);
                tabsAux.push(<TabButton key={item.props.title} index={itemIndex} active={active}  activeChanger={setActive}>{item.props.title}</TabButton>);
            });
        }

        setSections(sectionsAux);
        setTabs(tabsAux);
    },[children,active]);

    return(
        <div className="tabs-card">
            <ul className="tabs-buttons">
                { tabs }
            </ul>
            <div className="tabs-contents">
                { sections[active] }
            </div>
        </div>
    );
};

export const TabSection = () => {
    return true;
};

const TabButton = ({index, active, children, activeChanger}) => {
    function changer(e){
        activeChanger(parseInt(e.target.getAttribute("index")));
    }

    return(
        <li className={ (active === index) ? "active" : "" }><button index={index} onClick={changer}>{children}</button></li>
    );
};

const TabItem = ({index, active , children}) => {
    return(
        <div className={ (active === index) ? "tab-item active" : "tab-item" }>
            {children}
        </div>
    );
};