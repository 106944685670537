import { useRef, useState } from "react";
import { useAuth } from "../authcontext";

const ForgotPassword = () => {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage("");
            setError("");
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage("Check your inbox for further instructions");
        } catch {
            setError("Failed to reset password");
        }

        setLoading(false);
    }

    return(
        <div>
            <form onSubmit={handleSubmit}>
                {error && <div>{error}</div>}
                {message && <div>{message}</div>}
                <input type="email" ref={emailRef} placeholder="Correo Electrónico" />
                <button disabled={loading} type="submit">Enviar Correo</button>
            </form>
        </div>
    );
};

export default ForgotPassword;