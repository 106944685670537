import { useEffect, useState } from "react";
import OrdersTable from "./ordersTable"

const Orders = () => {
    const [ selectedDate, setSelectedDate ] = useState();
    const months = [{name:"Enero",number:"01"},{name:"Febrero",number:"02"},{name:"Marzo",number:"03"},{name:"Abril",number:"04"},{name:"Mayo",number:"05"},{name:"Junio",number:"06"},{name:"Julio",number:"07"},{name:"Agosto",number:"08"},{name:"Septiembre",number:"09"},{name:"Octubre",number:"10"},{name:"Noviembre",number:"11"},{name:"Diciembre",number:"12"}];
    // const weekDays = ["Lunes","Martes","Miércoles","Jueves","Viernes","Sábado","Domingo"];

    useEffect(() => {
        const date = new Date();
        let day = date.getDate();
        if(day < 10) day = `0${date.getDate()}`;
        setSelectedDate(`${date.getFullYear()}-${months[date.getMonth()].number}-${day}`);
    },[]);

    function handleChangeDate(e){
        setSelectedDate(e.target.value);
    }

    return(
        <div>
            <h1 className="title">Pedidos</h1>
            { selectedDate &&
                <div> 
                    <p><strong>Pedidos para: </strong> <input type="date" value={selectedDate} onChange={handleChangeDate} /></p>
                    <OrdersTable date={selectedDate} />
                </div>
            }
        </div>
    );
};

export default Orders;