

const ChangeOrderButton = ({ordered,shipping,productIndex,storeIndex,onClick}) => {

    function handleClick(e){
        const text = e.target.innerHTML;
        let values = text.split(' | ');
        onClick(values,productIndex,storeIndex);
    }

    return(
        <div>
            <button onClick={handleClick} className={ shipping > 0 ? "table-btn black":"table-btn"}>
                {ordered} | {shipping}
            </button>
        </div>
    );
};

export default ChangeOrderButton;