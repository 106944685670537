import { useEffect, useState } from "react";
import useiGitBackAPI from "../../API/igitback";
import { Table } from "../general/table";

const OrdersTable = ({date}) => {
    //const [ products, setProducts ] = useState([]);
    const [ orders, setOrders ] = useState([]);

    useEffect(() => {
        const { getAllOrdersByDate, getAllProducts } = useiGitBackAPI();
        const promises = [];

        
        
        promises.push(getAllOrdersByDate(date));
        promises.push(getAllProducts());

        Promise.all(promises).then(([allOrders,allProducts]) => {
            const columns = ["Productos"];
            let rows = [];
            
            allOrders.forEach((order, orderIndex) => {
                columns.push({id: order.storeId, text:`${order.storeKey} - ${order.storeName}`});

                order.products.forEach(product => {
                    const productIndex = allProducts.findIndex(item => item.id === product.id);
                    const rowIndex = rows.findIndex(row => row.id === product.id);

                    if(rowIndex < 0){
                        let storesAmounts = [];
                        for(let i = 0;i < allOrders.length;i++){
                            if(orderIndex === i)
                                storesAmounts[i] = {ordered: parseInt(product.amount),shipping: parseInt(product.amount)};
                            else
                                storesAmounts[i] = {ordered: 0,shipping: 0};
                        }

                        rows.push({product: allProducts[productIndex].name,id: product.id,sku: allProducts[productIndex].sku, stores: storesAmounts});

                    }else{
                        rows[rowIndex].stores[orderIndex] = {ordered: parseInt(product.amount),shipping: parseInt(product.amount)};
                    }
                });
            });
            
            setOrders([columns,rows]);
        });
    },[date]);

    function printData(){
        let thead = '';
        let tbody = '';
        let colSum = [];
        orders[0].forEach((column) => {
            if(column === "Productos")
                thead += '<th style="width:70px;text-align:center;border-bottom:1px solid #000;padding:2px;">Productos</th>';
            else{
                thead += `<th style="width:70px;text-align:center;border-bottom:1px solid #000;padding:2px;">${column.text}</th>`;
                colSum.push(0);
            }
        });
        thead += '<th style="text-align:center;border-bottom:1px solid #000;padding:2px;">Total</th>'

        orders[1].forEach((row) => {
            tbody += `<tr><th style="text-align:left;border-bottom:1px solid #000;padding:2px;"><span style="display:block;font-size:0.8em;">${row.sku}</span><strong>${row.product}</strong></th>`;
            let rowSum = 0;

            row.stores.forEach((store,storeIndex) => {
                tbody += `<td style="text-align:center;border-bottom:1px solid #000;padding:2px;">${store.ordered}</td>`;
                rowSum += parseInt(store.ordered);
                colSum[storeIndex] += parseInt(store.ordered);
            });

            tbody += `<td style="text-align:center;border-bottom:1px solid #000;padding:2px;">${rowSum}</td></tr>`;
        });
        tbody += `<tr><th style="text-align:right;border-bottom:1px solid #000;padding:2px;">Total:</th>`;
        colSum.forEach((column) => {
            tbody += `<td style="text-align:center;border-bottom:1px solid #000;padding:2px;">${column}</td>`;
        });
        tbody += '</tr>';

        const table = `<table style="font-size:0.8em;">
            <thead>
                <tr>
                    ${thead}
                </tr>
            </thead>
            <tbody>
                <tr>
                    ${tbody}
                </tr>
            </tbody>
        </table>`;
        const newWin = window.open("");

        newWin.document.write(table);
        newWin.print();
        newWin.close();
    }

    function downloadCSV(e){
        e.preventDefault();

        let csv = "data:text/csv;charset=utf-8,";
        const tableRows = document.querySelector("#orders-table").rows;
        for(let i = 0;i < tableRows.length;i++){
            const tableCells = tableRows[i].cells;
            for(let j = 0;j < tableCells.length;j++){
                const item = tableCells[j].children.item(0);
                if(item){
                    if(item.className === "product-title"){
                        csv += `${item.children.item(0).innerText} - ${item.children.item(1).innerText},`;
                    }else{
                        if(item.className === "btn btn-primary")
                            csv += "Total,";
                        else
                            csv += `${tableCells[j].innerText},`;
                    }
                }else{
                    csv += `${tableCells[j].innerText},`;
                }
            }
            csv += `\r\n`;
        }
        
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `pedidos${date}.csv`);
        document.body.appendChild(link);
         /* download the data file named "Stock_Price_Report.csv" */
        link.click();
    }

    let colsSum = [];

    return(
        <div>
            
            { orders.length > 0 &&
                (<Table id="orders-table"
                    columns={orders[0].map(column => {
                        if(column === "Productos")
                            return column;
                        else{
                            colsSum.push(0);
                            return column.text;
                        }
                    }).concat(<button className="btn btn-primary" onClick={downloadCSV}><i className="fas fa-file-excel" /></button>)}
                    rows={orders[1].map((row) => {
                        const columns = [<div className="product-title"><span>{row.sku}</span><strong>{row.product}</strong></div>];
                        let rowSum = 0;

                        row.stores.forEach((store,storeIndex) => {
                            rowSum += parseInt(store.ordered);
                            colsSum[storeIndex] += parseInt(store.ordered);
                            columns.push(<div style={{textAlign:"center"}}>{store.ordered}</div>);
                        });
                        return columns.concat(<div style={{textAlign:"center"}}>{rowSum}</div>);
                    })}
                    footer={orders[0].map((column,columnIndex) => {
                        if(column === "Productos")
                            return (<div style={{textAlign:"right"}}><strong>Total:</strong></div>);
                        else
                            return (<div style={{textAlign:"center"}}>{colsSum[columnIndex-1]}</div>);
                    })}
                />)
            }
        </div>
    );
};

export default OrdersTable;