import "../../assets/css/orders.css";
import { useEffect, useRef, useState } from "react";
import useiGitBackAPI from "../../API/igitback";
import { useAuth } from "../../authentication/authcontext";

const CreateOrders = () => {
    const storeRef = useRef();
    const [ minDate, setMinDate ] = useState('');
    const [ selectedDate, setSelectedDate ] = useState('');
    const [ stores, setStores ] = useState([]);
    const [ products, setProducts ] = useState([]);
    const [ order, setOrder ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const { getAllStores, getAllProducts, addNewOrder } = useiGitBackAPI();
    const { currentUser } = useAuth();
    
    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() +1;
        if(month < 10) month = `0${month}`;
        let day = today.getDate();
        if(day < 10) day = `0${day}`;

        setMinDate(`${year}-${month}-${day}`);
        setSelectedDate(`${year}-${month}-${day}`);
    },[]);

    useEffect(() => {
        getAllStores().then(data => setStores(data));
        getAllProducts().then(data => setProducts(data));
    },[]);

    function handleChangeDate(e){
        setSelectedDate(e.target.value);
    }

    function handleChangeAmount(e){
        const value = e.target.value;
        const id = e.target.id;
        const orderAux = order;
        const orderIndex = orderAux.findIndex((product) => product.productId === id);
        if(value){
            if(orderIndex < 0)
                orderAux.push({productId:id,amount:value});
            else
                orderAux[orderIndex].amount = value;
        }else{
            if(orderIndex > -1)
                orderAux.splice(orderIndex,1);
        }

        setOrder(orderAux);
    }

    function handleClickSend(e){
        if(storeRef.current.value != ""){
            setLoading(true);
            const orderFormat = {
                userId: currentUser.uid,
                storeId: storeRef.current.value,
                dateToSend: selectedDate,
                products: order
            };
            addNewOrder(orderFormat).then((response) => {
                if(response.success){
                    alert("¡Pedido creado correctamente!");
                    window.location.reload();
                }else{
                    alert("¡Hubo un error al crear el Pedido!")
                }
                setLoading(false);
            });
        }else
            alert("Es necesario seleccionar una tienda");
    }

    return(<div>
        <h1 className="title">Crear Pedidos</h1>
        <div className="data-selector">
            <label>
                <p>Selecciona una Tienda</p>
                <select ref={storeRef}>
                    <option value="">Tienda</option>
                    {stores.map(store => {
                        return(<option key={store.id} value={store.id}>{`${store.key} - ${store.name}`}</option>);
                    })}
                </select>
            </label>
            <label>
                <p>¿Para cuándo lo piden?</p>
                <input type="date" min={minDate} value={selectedDate} onChange={handleChangeDate} />
            </label>
        </div>
        <div className="products-list">
            {products.map(product => {
                return(<div className="product-item" key={product.id}>
                    <div className="desc"><small>{product.sku}</small><p>{product.name}</p></div>
                    <div className="amount"><input id={product.id} type="number" placeholder="0" onChange={handleChangeAmount} /></div>
                </div>);
            })}
        </div>
        <div className="wrap-button">
            <button className="btn btn-primary" onClick={handleClickSend} disabled={loading}>Crear Pedido</button>
        </div>
    </div>);
};

export default CreateOrders;