import { useEffect, useState } from "react";
import { TabsCard, TabSection } from "../general/tabsCard";
import OrdersToBeSent from "./shippingsTable";
import ShippingsTable from "./ordersToBeSent";

const Shippings = () => {
    const [ today, setToday ] = useState();
    const [ selectedDate, setSelectedDate ] = useState();
    const months = [{name:"Enero",number:"01"},{name:"Febrero",number:"02"},{name:"Marzo",number:"03"},{name:"Abril",number:"04"},{name:"Mayo",number:"05"},{name:"Junio",number:"06"},{name:"Julio",number:"07"},{name:"Agosto",number:"08"},{name:"Septiembre",number:"09"},{name:"Octubre",number:"10"},{name:"Noviembre",number:"11"},{name:"Diciembre",number:"12"}];
    const weekDays = ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"];

    useEffect(() => {
        const date = new Date();
        let day = date.getDate();
        if(day < 10) day = `0${day}`;
        setToday(date);
        setSelectedDate(`${date.getFullYear()}-${months[date.getMonth()].number}-${day}`);
    },[]);

    return(
        <div>
            <TabsCard>
                <TabSection title="Envíos">
                    <h1 className="title">Por Enviar</h1>
                    { today &&
                        <div>
                            <p><strong>Envíos para: </strong>{`${weekDays[today.getDay()]} ${today.getDate()} de ${months[today.getMonth()].name} del ${today.getFullYear()}`}</p>
                            <ShippingsTable date={selectedDate} />
                        </div>
                    }
                </TabSection>
                <TabSection title="Entregas">
                    <h1 className="title">Entregas</h1>
                    { today &&
                        <div>
                            <p><strong>Entregas para: </strong>{`${weekDays[today.getDay()]} ${today.getDate()} de ${months[today.getMonth()].name} del ${today.getFullYear()}`}</p>
                            <OrdersToBeSent date={selectedDate} />
                        </div>
                    }
                </TabSection>
            </TabsCard>
        </div>
    );
};

export default Shippings;