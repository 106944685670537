import { useEffect, useRef, useState } from "react";
import useiGitBackAPI from "../../API/igitback";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../general/modal";
import { Table } from "../general/table";
import ChangeOrderButton from "./changeOrderButton";
import { useAuth } from "../../authentication/authcontext";
import { remisions } from "../../helpers/templates";

const OrdersToBeSent = ({date}) => {
    //const [ products, setProducts ] = useState([]);
    const [ shipping, setShipping ] = useState([]);
    const [ orders, setOrders ] = useState([]);
    const [ vehicles, setVehicles ] = useState([]);
    const [ modalActive,setModalActive ] = useState(false);
    const [ confirmModalActive, setConfirmModalActive ] = useState(false);
    const [ currentOrderIndex, setCurrentOrderIndex ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ storeIndexGlobal, setStoreIndexGlobal ] = useState();
    const { currentUser } = useAuth();
    const changeInputRef = useRef();
    const carInputRef = useRef();
    const operatorInputRef = useRef();
    const { setNewShipping } = useiGitBackAPI();

    useEffect(() => {
        const { getAllOrdersWithoutShippings, getAllProducts, getAllVehicles } = useiGitBackAPI();
        const promises = [];
        
        promises.push(getAllOrdersWithoutShippings(date)); 
        promises.push(getAllProducts());
        promises.push(getAllVehicles());

        Promise.all(promises).then(([allOrders,allProducts,getAllVehicles]) => {
            const columns = ["Productos"];
            let rows = [];
            
            allOrders.forEach((order, orderIndex) => {
                columns.push({id: order.storeId, text:`${order.storeKey} - ${order.storeName}`});

                order.products.forEach(product => {
                    const productIndex = allProducts.findIndex(item => item.id === product.id);
                    const rowIndex = rows.findIndex(row => row.id === product.id);

                    if(rowIndex < 0){
                        let storesAmounts = [];
                        for(let i = 0;i < allOrders.length;i++){
                            if(orderIndex === i)
                                storesAmounts[i] = {ordered: parseInt(product.amount),shipping: parseInt(product.amount)};
                            else
                                storesAmounts[i] = {ordered: 0,shipping: 0};
                        }

                        rows.push({product: allProducts[productIndex].name,id: product.id,sku: allProducts[productIndex].sku, stores: storesAmounts});

                    }else{
                        rows[rowIndex].stores[orderIndex] = {ordered: parseInt(product.amount),shipping: parseInt(product.amount)};
                    }
                });
            });

            const vehiclesAux = [];
            getAllVehicles.forEach(vehicle => {
                vehiclesAux.push({
                    id: vehicle.id,
                    name: vehicle.name
                });
            });
            
            setOrders([columns,rows]);
            setVehicles(vehiclesAux);
        });
    },[date]);
    
    function toggleModal(){
        const active = modalActive;
        setModalActive(!active);
    }

    function toggleConfirmModal(){
        const active = confirmModalActive;
        setConfirmModalActive(!active);
    }

    function handleClickChangeOrderButton([ordered,shipping],productIndex,storeIndex){
        changeInputRef.current.value = shipping;
        setCurrentOrderIndex([productIndex,storeIndex]);
        toggleModal();
    }

    function handleClickAcceptChangeOrder(e){
        const ordersList = orders;
        const [ productIndex, storeIndex ] = currentOrderIndex;

        ordersList[1][productIndex].stores[storeIndex].shipping = changeInputRef.current.value;
        toggleModal();
    }

    function handleClickSendButton(e){
        const storeIndex = parseInt(e.target.getAttribute("storeindex"));
        const storeId = orders[0][storeIndex+1].id;
        const storeText = orders[0][storeIndex+1].text;
        const shippingAux = {
            storeId,
            storeText,
            products: []
        }

        orders[1].forEach((product) => {
            const amount = parseInt(product.stores[storeIndex].shipping);
            if(amount > 0){
                shippingAux.products.push({
                    productId: product.id,
                    name: product.product,
                    sku: product.sku,
                    amount
                });
            }
        });

        setStoreIndexGlobal(storeIndex);
        setShipping(shippingAux);
        toggleConfirmModal();
    }

    function deleteStoreOrder(){
        let orderAux = orders;

        orderAux[0].splice(storeIndexGlobal+1,1);
        orderAux[1].forEach((product) => {
            product.stores.splice(storeIndexGlobal,1);
        });
    }

    function handleSendShipping(e){
        e.preventDefault();
        setLoading(true);
        
        let shippingAux = shipping;
        shippingAux.car = carInputRef.current.value;
        shippingAux.operator = operatorInputRef.current.value;
        shippingAux.userId = currentUser.uid;

        printRemision();

        setLoading(false);

        setNewShipping(shippingAux).then((data) => {
            if(data.success !== undefined){
                carInputRef.current.value = "";
                operatorInputRef.current.value = "";

                deleteStoreOrder();
                toggleConfirmModal();
                setLoading(false);
            }
        });
    }

    function printRemision(){
        const newWin = window.open();

        newWin.document.write(remisions(shipping));
        setTimeout(() => {
            newWin.print();
            newWin.close();
        },500);
        
    }

    let colsSum = [];

    return(
        <div>
            { orders.length > 0 &&
                (<Table
                    columns={orders[0].map(column => {
                        if(column === "Productos")
                            return column;
                        else{
                            colsSum.push([0,0]);
                            return column.text;
                        }
                    })}
                    rows={orders[1].map((row,rowIndex) => {
                        const columns = [<div className="product-title"><span>{row.sku}</span><strong>{row.product}</strong></div>];
                        let rowSum = [0,0];

                        row.stores.forEach((store,storeIndex) => {
                            rowSum[0] += parseInt(store.ordered);
                            rowSum[1] += parseInt(store.shipping);
                            colsSum[storeIndex][0] += parseInt(store.ordered);
                            colsSum[storeIndex][1] += parseInt(store.shipping);
                            columns.push(<ChangeOrderButton ordered={store.ordered} shipping={store.shipping} productIndex={rowIndex} storeIndex={storeIndex} onClick={handleClickChangeOrderButton} />);
                        });
                        return columns.concat(<div style={{textAlign:"center"}}>{rowSum[0]} | {rowSum[1]}</div>);
                    }).concat([orders[0].map((column, columnIndex) => {
                        if(column === "Productos")
                            return (<div style={{textAlign:"right"}}><strong>Total:</strong></div>);
                        else
                            return (<div style={{textAlign:"center"}}>{`${colsSum[columnIndex-1][0]} | ${colsSum[columnIndex-1][1]}`}</div>);
                    })])}
                    footer={orders[0].map((column,columnIndex) => {
                        if(column === "Productos")
                            return "";
                        else
                            return(<button storeindex={(columnIndex-1)} className="table-btn primary" onClick={handleClickSendButton}>Enviar</button>);
                    })}
                />)
            }

            <Modal active={modalActive}>
                <ModalHeader>
                    <h2 className="title">¿Cuántos vamos a enviar?</h2>
                </ModalHeader>
                <ModalBody>
                    <input type="number" ref={changeInputRef} />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={handleClickAcceptChangeOrder}>Hacer Cambio</button>
                    <button className="btn btn-black" onClick={toggleModal}>Cancelar</button>
                </ModalFooter>
            </Modal>

            <Modal active={confirmModalActive}>
                <ModalHeader>
                    <h2 className="title">¿Quién llevará el Pedido?</h2>
                </ModalHeader>
                <form onSubmit={handleSendShipping}>
                    <ModalBody>
                        <label>
                            <select ref={carInputRef} required>
                                <option value="">Unidad</option>
                                {vehicles.map(vehicle => {
                                    return(<option key={vehicle.id} value={vehicle.id}>{vehicle.name}</option>);
                                })}
                            </select>
                        </label>
                        <label>
                            <input ref={operatorInputRef} type="text" placeholder="Operador" required />
                        </label>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary" type="submit" disabled={loading}>Enviar Pedido</button>
                        <button className="btn btn-black" type="button" onClick={toggleConfirmModal}>Cancelar</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    );
};

export default OrdersToBeSent;