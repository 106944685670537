const useiGitBackAPI = () => {
    const api = {
        //currentServer: "http://localhost/igitback/API/v1/frutamiaOperative/",
        currentServer: "https://frutamia.com.mx/igitback/API/v1/frutamiaOperative/",
        getAllOrdersWithoutShippings: async (date) => {
            return await fetch(`${api.currentServer}getAllOrdersWithoutShippings`,{
                method: "post",
                credentials: 'same-origin',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `date=${date}`
            }).then(response => response.json());
        },
        getAllOrdersByDate: async(date) => {
            return await fetch(`${api.currentServer}getAllOrdersByDate`,{
                method: "post",
                credentials: 'same-origin',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `date=${date}`
            }).then(response => response.json());
        },
        getAllShippingsByDate: async (date) => {
            return await fetch(`${api.currentServer}getAllShippingsByDate`,{
                method: "post",
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `date=${date}`
            }).then(response => response.json());
        },
        getAllProducts: async () => {
            return await fetch(`${api.currentServer}getAllProducts`).then(response => response.json());
        },
        setNewShipping: async (data) => {
            return await fetch(`${api.currentServer}setNewShipping`,{
                method: "post",
                headers: {
                    //'Content-Type': 'application/json'
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `shipping=${JSON.stringify(data)}`
            }).then(response => response.json());
        },
        getAllVehicles: async () => {
            return await fetch(`${api.currentServer}getAllVehicles`,{
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }).then(response => response.json());
        },
        getAllStores: async () => {
            return await fetch(`${api.currentServer}getAllStores`,{
                method: "post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }).then(response => response.json());
        },
        addNewOrder: async (order) => {
            return await fetch(`${api.currentServer}setNewOrder`,{
                method: "post",
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `order=${JSON.stringify(order)}`
            }).then(response => response.json());
        }
    };

    return api;
};

export default useiGitBackAPI;