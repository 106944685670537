import { useEffect, useState } from "react";
import useiGitBackAPI from "../../API/igitback";
import { Table } from "../general/table";

const ShippingsTable = ({date}) => {
    const [ orders, setOrders ] = useState([]);

    useEffect(() => {
        const { getAllShippingsByDate, getAllProducts } = useiGitBackAPI();
        const promises = [];

        promises.push(getAllShippingsByDate(date));
        promises.push(getAllProducts());

        Promise.all(promises).then(([allOrders,allProducts]) => {
            const columns = ["Productos"];
            let rows = [];
            
            allOrders.forEach((order, orderIndex) => {
                columns.push({id: order.storeId, text:`${order.storeKey} - ${order.storeName}`, operator:order.operator, vehicle:order.vehicle});

                order.products.forEach(product => {
                    const productIndex = allProducts.findIndex(item => item.id === product.id);
                    const rowIndex = rows.findIndex(row => row.id === product.id);

                    if(rowIndex < 0){
                        let storesAmounts = [];
                        for(let i = 0;i < allOrders.length;i++){
                            if(orderIndex === i)
                                storesAmounts[i] = {ordered: parseInt(product.amount),shipping: parseInt(product.amount)};
                            else
                                storesAmounts[i] = {ordered: 0,shipping: 0};
                        }

                        rows.push({product: allProducts[productIndex].name,id: product.id,sku: allProducts[productIndex].sku, stores: storesAmounts});

                    }else{
                        rows[rowIndex].stores[orderIndex] = {ordered: parseInt(product.amount),shipping: parseInt(product.amount)};
                    }
                });
            });
            
            setOrders([columns,rows]);
        });
    },[date]);

    let colsSum = [];

    return(
        <div>
            { orders.length > 0 &&
                (<Table
                    columns={orders[0].map(column => {
                        if(column === "Productos")
                            return column;
                        else{
                            colsSum.push(0);
                            return <span><p>{column.text}</p><p>{column.operator}</p><p>{column.vehicle}</p></span>;
                        }
                    })}
                    rows={orders[1].map((row) => {
                        const columns = [<div className="product-title"><span>{row.sku}</span><strong>{row.product}</strong></div>];
                        let rowSum = 0;

                        row.stores.forEach((store,storeIndex) => {
                            rowSum += parseInt(store.ordered);
                            colsSum[storeIndex] += parseInt(store.ordered);
                            columns.push(<div style={{textAlign:"center"}}>{store.ordered}</div>);
                        });
                        return columns.concat(<div style={{textAlign:"center"}}>{rowSum}</div>);
                    })}
                    footer={orders[0].map((column,columnIndex) => {
                        if(column === "Productos")
                            return (<div style={{textAlign:"right"}}><strong>Total:</strong></div>);
                        else
                            return (<div style={{textAlign:"center"}}>{colsSum[columnIndex-1]}</div>);
                    })}
                />)
            }
        </div>
    );
};

export default ShippingsTable;