import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./assets/css/general.css";
import "./assets/css/print.css";
import { AuthProvider } from "./authentication/authcontext";
import ForgotPassword from "./authentication/pages/forgotPassword";
import Login from "./authentication/pages/login";
import PrivateRoute from "./authentication/privateRoute";
import Theme from "./components/theme";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          {/* Auth */}
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Theme */}
          <PrivateRoute exact path="/">
            <Redirect to="/app" />
          </PrivateRoute>
          <PrivateRoute path="/app" component={Theme} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
