import React, { useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../authcontext"
import "../../assets/css/access.css"

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      history.push("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (
    <div className="access-box">
      <div className="card access-card">
        <h2 className="text-center mb-4 title">Iniciar Sesión</h2>
        {error && <div>{error}</div>}
        <form onSubmit={handleSubmit}>
            <input type="email" placeholder="Correo Electrónico" ref={emailRef} required />
            <input type="password" placeholder="Contraseña" ref={passwordRef} required />
            <button disabled={loading} type="submit">
                Entrar
            </button>
        </form>
        <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Olvidé mi contraseña</Link>
        </div>
      </div>
    </div>
  )
}