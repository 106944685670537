import "../../assets/css/table.css";

export const Table = ({columns,rows,footer,...rest}) => {
    return(
        <table {...rest}>
            {columns && <thead>
                <tr>
                    {columns.map((column) => {
                        return(
                            <th key={column}>{column}</th>
                        );
                    })}
                </tr>
            </thead>}
            <tbody>
                {rows.map((row,rowIndex) => {
                    return(
                        <tr key={rowIndex}>
                            {row.map((col,colIndex) => {
                                return(
                                    <td key={`${colIndex}${col}`}>{col}</td>
                                );
                            })}
                        </tr>
                    );
                })}
                <tr>
                    { footer && footer.map((column,columnIndex) => {
                        return(<td key={`${columnIndex}${column}`}>{column}</td>);
                    })}
                </tr>
            </tbody>
        </table>
    );
}